
import styles from './AdminFarmersScreen.module.css';
import helpers from '../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../UserContext.js';

import FarmerDetails from './farmers/FarmerDetails.js'
import SubscriptionDetails from './farmers/SubscriptionDetails.js'
import Orders from './farmers/Orders.js';
import Invoices from './farmers/Invoices.js'



import Nav from "../components/Nav.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Module from "../components/Module.js";
import TextInput from "../components/TextInput.js";
import Selector from "../components/Selector";
import Button from "../components/Button.js";
import Overlay from "../components/Overlay.js";

//
const farmersArray = [
  {
    email: "",
    language: "en-UK",
    _supervisor: {
      $oid: "6513c72dafb464a76c57713e"
    },
    referral_code: "Xv2QB92T",
    referral_expiry: {
      $date: {
        $numberLong: "1696408607439"
      }
    },
    billing_details: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      business_name: "",
      city: "",
      zip: "",
      county: ""
    },
    delivery_details: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      business_name: "",
      city: "",
      zip: "",
      county: "",
      instructions: ""
    },
    subscription: {
      status: "inexistant",
      payment_plan: "none",
      amount_due: 0,
      amount_paid: 0,
      products: []
    },
    platform_settings: {
      enable_advanced_analytics: false,
      enable_dark_mode: false,
      metric_volume_units: false,
      metric_surface_units: false
    },
    notification_settings: {
      subscribed_to_newsletter: false,
      enable_email_alerts: true,
      enable_sms_alerts: false,
      enable_paper_invoices: false
    },
    _invoices: [],
    _orders: [],
    questionnaire_data: [
      {
        questionName: "Farm Activities",
        tags: [
          "Cereals",
          "General cropping",
          "Dairy",
          "Grazing livestock (Lowland)",
          "Grazing livestock (LFA)",
          "Pigs",
          "Poultry",
          "Mixed",
          "Horticulutre"
        ],
        selectedTags: [
          "Mixed"
        ]
      },
      {
        questionName: "Priorities",
        tags: [
          "Bedding conditions",
          "Improved organic fertilizer",
          "Environmental protection",
          "Crop yield",
          "Soil water",
          "Soil yield"
        ],
        selectedTags: [
          "Environmental protection",
          "Improved organic fertilizer"
        ]
      }
    ],
    is_deleted: false,
    created_at: {
      $date: {
        $numberLong: "1695803807441"
      }
    },
    __v: 1,
    business_name: "Black Bull Biochar",
    country: "GB",
    first_name: "",
    last_name: "",
    password: "$2a$10$P5c02AU9//ljR.wIHflPuesYFxvf1pnJhjWNoX19PD7zRKz0oseki",
    phone_home: "",
    phone_mobile: ""
  }
]



function AdminFarmersScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedFarmerIndex, setSelectedFarmerIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState("Details");
  const [farmers, setFarmers] = useState(farmersArray);
  const [displayInvite, setDisplayInvite] = useState(false);
  const [emailFarmerInvite, setEmailFarmerInvite] = useState("");


  const fetchFarmers = async () => {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    console.log(user.backEndURL);
    let response = await fetch(user.backEndURL + "/users", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      // let newFarmers = [];
      // jsonResponse.data.forEach((farmer) => {
      //   let farmerDate = new Date(farmer.delivery_date)
      //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
      //   let newFarmer = {
      //     id: farmer._id,
      //     status: farmer.status,
      //     deliveryDate: dateString,
      //     pincharBags: farmer.products[0].amount,
      //     pelletcharBags: farmer.products[1].amount,
      //     weight: 2.2,
      //     volume: 8
      //   }
      //   newFarmers.push(newFarmer);
      // })

      setFarmers(jsonResponse.data);
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  useEffect(() => {
    console.log("Fetching Farmers")
   
    fetchFarmers();
  },[user])

  const renderFarmerList = () => {
    // let filteredFarmers = [];
    // if(selectedFilter === "all"){
    //   filteredFarmers = farmers;
    // }else {
    //   filteredFarmers = farmers.filter(function (farmer) {
    //       return farmer.status === selectedFilter 
    //     }
    //   );
    // }

    const renderStatus = (status) => {
      switch(status){
        // case "delivered":
        //   return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Delivered</td>
        // case "upcoming":
        //   return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
        // case "cancelled":
        //   return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
        default:
            return <td>N/A</td>
      }

    }
  

    return(
      <>
        {farmers.map((farmer, index) => {
          // console.log(farmer);
          return(
            <tr className={helpers.clx(styles.tableRow, index === selectedFarmerIndex ? styles.tableRowSelected : "")} onClick={() => setSelectedFarmerIndex(index)}>
              <td className={styles.dataBold}>{farmer.first_name + " " + farmer.last_name}</td>
              <td className={styles.dataBold}>{farmer.email}</td>
              <td className={styles.dataBold}>{farmer.phone_mobile}</td>
              <td className={styles.dataBold}>{"TBC"}</td>
              <td className={styles.dataBold}>{"TBC"}</td>
              {renderStatus(farmer.status)}
            </tr>
          ) 
        })
      }
      </>
    )
  }

  const inviteFarmer = async () => {
    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        email: emailFarmerInvite,
        supervisorId: "6513c72dafb464a76c57713e"
      })
    };
    let response = await fetch(user.backEndURL + "/invite-user", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      alert("Successfully invited " + emailFarmerInvite);
      setDisplayInvite(false);
    } else {
      alert("Something went wrong...")
    }
    
  }//


  const updateFarmerList = () => {
    fetchFarmers();
  }

  const renderFarmerContent = () => {
    switch(selectedTab){
      case "Details":
        return <FarmerDetails user={farmers[selectedFarmerIndex]}/>
      case "Subscription":
        let farmer = {details:{
          businessName: farmers[selectedFarmerIndex].business_name
        }}
        let subscription = {
          supervisorName: "Arla",
          payment_plan: farmers[selectedFarmerIndex].subscription.payment_plan
        }
        return <SubscriptionDetails user={farmer} subscription={subscription}/>//<DetailsTable user={farmer} subscription={subscription}/>
      case "Orders":
        return <Orders  updateFarmerList={updateFarmerList} farmer={farmers[selectedFarmerIndex]}/>
      case "Invoices":
        return <Invoices  updateFarmerList={updateFarmerList} farmer={farmers[selectedFarmerIndex]}/>
      default:
          return <td>FEATURE COMING UP</td>
    }

  }//

  

  const renderFarmerInvite = () => {
    console.log("HEREInvite")
    if(displayInvite){
      return(
        <Overlay>
        <div className={styles.farmerInviteContainer}>
          <h2>Invite New Farmer</h2>
          <Selector  labelStyle={"top"} name={"Supervisor"} value={"6513c72dafb464a76c57713e"} data={[{value: "6513c72dafb464a76c57713e", name: "Arla"}]}/>
          <TextInput value={emailFarmerInvite} onChange={setEmailFarmerInvite} labelStyle={"top"} name={"Email"} placeholder={"farmer@email.com"}/>
          <Button name={"Send Invite"} onPress={inviteFarmer}/>
          <Button name={"Cancel"} color={"Error"} onPress={() => setDisplayInvite(false)}/>
        </div>
      </Overlay>
      )
    }else{
      return null;
    }
  }
  
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} isAdmin={true} currentScreen={"admin/farmers"}/>
      {renderFarmerInvite()}

      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <div className={styles.inviteFarmerButton}>
            <Button name="Invite New Farmer" onPress={() => setDisplayInvite(true)}/>
          </div>
          <ScreenHeader name={"Farmers"} content={"Browse through your biochar farmers. Don’t hesitate to contact us if you have any questions!"}/>
          <div className={"contentGrid"}>
            <Module spanColumn={24} spanRow={3} height={"380px"}>
              <div className={styles.farmersTableWrapper}>
                <table className={styles.farmersTable}>
                  <tr className={styles.farmersHeaderRow}>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Next Invoice</th>
                    <th>Next Delivery</th>
                  </tr>
                  {renderFarmerList()}
                </table>
              </div>  
             
            </Module>
            <Module spanColumn={24} spanRow={2} height={"100%"}>
              <div className={styles.farmerHeaderContainer}>
                <div className={styles.farmerHeaderName}>
                  {farmers[selectedFarmerIndex].first_name + " " + farmers[selectedFarmerIndex].last_name}
                </div>
                <div className={styles.farmerHeaderBusiness}>
                  {farmers[selectedFarmerIndex].business_name}
                </div>
                <div className={styles.farmerHeaderLine}/>
              </div>
              <div className={styles.farmerContentContainer}>
                <div className={styles.farmerContentMenuTabs}>
                  <div className={helpers.clx(styles.menuTab, selectedTab === "Details" ? styles.menuTabSelected : "")} onClick={()=> {setSelectedTab("Details")}}>
                    Details
                  </div>
                  <div className={helpers.clx(styles.menuTab, selectedTab === "Subscription" ? styles.menuTabSelected : "")} onClick={()=> {setSelectedTab("Subscription")}}>
                    Subscription
                  </div>
                  <div className={helpers.clx(styles.menuTab, selectedTab === "Orders" ? styles.menuTabSelected : "")} onClick={()=> {setSelectedTab("Orders")}}>
                    Orders
                  </div>
                  <div className={helpers.clx(styles.menuTab, selectedTab === "Invoices" ? styles.menuTabSelected : "")} onClick={()=> {setSelectedTab("Invoices")}}>
                    Invoices
                  </div>
                </div>
                <div className={styles.farmerContentPage}>
                  {renderFarmerContent()}
                </div>
              </div>
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}
//

export default AdminFarmersScreen;
