
import {useState, useContext} from "react";
import { UserContext } from './UserContext.js';

import styles from './SettingsScreen.module.css';
import helpers from './helpers.js';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import TextInput from "./components/TextInput.js";
import Button from "./components/Button.js";

import UserDetails from "./components/settings/UserDetails";
import PlatformSettings from "./components/settings/PlatformSettings";
import NotificationSettings from "./components/settings/NotificationSettings";
import UpdateLogo from "./components/settings/UpdateLogo";



function SettingsScreen(props) {
  const {user, setUser} = useContext(UserContext);

  const updateSettings = async (userDetails, platformSettings, notificationSettings) => {
    const request = {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        body: JSON.stringify({
          userDetails: userDetails,
          platformSettings: platformSettings,
          notificationSettings: notificationSettings
        }),
      };
  
      let response = await fetch(user.backEndURL + "/update-settings", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        // props.goToScreen("confirm-subscription")
      }else {
        // helpers.errorPrompt("Oops something went wrong while updating...", null, setPrompt, null)
      }
    return;
  };

  
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"settings"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Settings"} content={"Update your account, platform, and notifications settings. Don’t forget to save your changes!"}/>
          <div className={helpers.clx("contentGrid", styles.contentGrid)}>
            <Module name={"User Details"} spanColumn={10} spanRow={2} height={"100%"}>
              <UserDetails updateSettings={updateSettings}/>
            </Module>
            <Module name={"Platform Settings"}  spanColumn={14} spanRow={1} height={"auto"}>
              <PlatformSettings updateSettings={updateSettings}/>
            </Module>
            <Module name={"Notification Settings"}  spanColumn={14} spanRow={1} height={"auto"}>
              <NotificationSettings updateSettings={updateSettings}/>
            </Module>
           
            <Module name={"Update Farm Logo"} spanColumn={10} spanRow={1} height={"100%"}>
              <UpdateLogo/>
            </Module>
            <Module name={"Delete Account"}  spanColumn={14}  spanRow={1} height={"100%"}>
              <div className={styles.deleteAccountContainer}>
                <div className={styles.deleteText}>
                Warning: deleting your account is permanent. If you have an ongoing subscription with remaining orders or invoices, your account may not be updated. For more info feel free to contact us.
                </div>
                <div className={styles.deleteButtonRow}>
                  <Button onPress={() => alert("Account was deleted...not")} name={"Delete Account"} color={"Error"}/>
                </div>
              </div>
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsScreen;
