import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import SignatureCanvas from 'react-signature-canvas'


import { UserContext } from "./UserContext.js";


import helpers from "./helpers.js";
import styles from './CharcodeScreen.module.css'; //styles

import Button from "./components/Button.js";
import QRScanner from "./components/QRCodeScanner.js";
import TextInput from "./components/TextInput.js";
import Selector from "./components/Selector.js";
import DateSelector from "./components/DateSelector.js";





import greenCircles from "./assets/images/greenCirclesCorner.png";
import bbbLogo from "./assets/images/bbbLogo.png";
import baggingImage from "./assets/images/baggingImage.png";
import pickupImage from "./assets/images/pickupImage.png";
import deliveryImage from "./assets/images/deliveryImage.png";
import applicationImage from "./assets/images/applicationImage.png";

import successImage from "./assets/images/check.png";
import dots from "./assets/images/patternDots.png";
import ebc from "./assets/images/EBC.png";





const CharcodeScreen = (props) => {
  const [location, setLocation] = useState({ lat: null, long: null });
  const {user, setUser} = useContext(UserContext);
  const {charcodeId } = useParams();
  const [mainBag, setMainBag] = useState({});
  const [retry, setRetry] = useState(false);
  const [charcodesCollapsed, setCharcodesCollapsed] = useState(false);



  const d = new Date();

  //Bagging
  const [weight, setWeight] = useState(0);
  const [site, setSite] = useState("");

  //Pickup
  const [orders, setOrders] = useState([]);
  const [deliveries, setDeliveries] = useState([]);

  const [selectedDelivery, setSelectedDelivery] = useState("")
  const [selectedOrder, setSelectedOrder] = useState("")

  //Delivery
  const [bagsNumber, setBagsNumber] = useState(0);
  const [applicationDate, setApplicationDate] = useState(d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0'));
  const [endUse, setEndUse] = useState("");
  const [machinery, setMachinery] = useState("");


  const [baggingDate, setBaggingDate] = useState(d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0'));
  const [screen, setScreen] = useState("loading"); //bagging, pickup, delivery, application
  const [displayScanner, setDisplayScanner] = useState(false);

  const [scanResults, setScanResults] = useState([charcodeId.slice(-10)])

  const sigCanvas = useRef(null);

  const clearCanvas = () => {
    sigCanvas.current.clear();
  };

  // useEffect(() => {
  //   console.log(scanResults)
  //   const uniqueSet = new Set(scanResults);
  //   // Convert the Set back to an array
  //   const uniqueArr = [...uniqueSet];
  //   console.log(uniqueArr);
  // },[scanResults])
  const getLocation = async() => {
    if(location.lat !== null){
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("HEREHRE")
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          alert("Location access was denied. Please allow location access and try again.");
          setScreen("error");
          throw new Error("Location denied");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    console.log("Fetching Charcode")
    
    const fetchCharcode = async () => {
      try{
        console.log("HELLO")
        let gettingLocation = await getLocation();
        console.log("HELLO123s")
        console.log(location.lat);
        if(location.lat === null){
          return;
        }
        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"   
          }
        };
        let response = await fetch(props.backEndURL + "/charcode/" + charcodeId + "/" , request);
        console.log(props.backEndURL + "/charcode/" + charcodeId)
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          setMainBag(jsonResponse.data.bag);
          let bagScreen;
          switch(jsonResponse.data.bag.status){
            case "unassigned":
              bagScreen = "bagging";
              break;
            case "bagged":
              bagScreen = "pickup";
              console.log(jsonResponse.data);
              setDeliveries(jsonResponse.data.deliveries);
              break;
            case "pickedUp":
              bagScreen = "delivery";
              console.log(jsonResponse.data)
              setOrders(jsonResponse.data.orders);
              break;
            case "delivered":
              bagScreen = "application";
              break;
          }
          setScreen(bagScreen)
          console.log(jsonResponse.data);
          // setOrders(newOrders);
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
        } else {
          alert("Error: " + jsonResponse.message);
          setScreen("application")
          console.log("Fetch Bag Failed...")
        }
      }catch(err){
        // alert("OUPSSS");
        return;
      }
      
      
    }
    fetchCharcode();
  },[props.backEndURL, retry, location])

  const removeCharcode = (charcode) => {
    console.log(scanResults);
    setScanResults(prevScanResults => prevScanResults.filter(element => element !== charcode));
  }

  const addCharcode = (charcode) => {
    // alert("CHARCODS")
    // console.log(charcode);
    // console.log(scanResults);
    let copy = scanResults.map((x) => x);
    // console.log(copy);
    
    copy.push(charcode)
    let result = "";
     if(!scanResults.includes(charcode)){
      result = copy;
      setScanResults(result);
     } else {
      console.log("YAI")
      setScanResults(scanResults)
     }
    

  }

  

  const updateScannedCharcodes = (charcode, isBeingAdded) => {
    if(isBeingAdded){
      console.log("a")
      console.log(charcode);
      // console.log(scanResults);
      setScanResults(prevScanResults => [...prevScanResults, charcode])
    } else {
      const updatedItems = scanResults.filter((_, index) => index !== scanResults.indexOf(charcode));
      setScanResults(updatedItems)
    }
  }

  const collapseCharcodes = () => {
    setCharcodesCollapsed(!charcodesCollapsed)
  }

  const updateBagsStatus = async () => {


    //LOGIC HERE 

    let requestBody;
    let bags = [];
    switch(screen){
      case "bagging":
        scanResults.forEach((charcode) => {
          let bag = {
            charcode: charcode,
            baggingDate: baggingDate,
            weight: weight,
            site: site
          };
          bags.push(bag);
        })
        requestBody = JSON.stringify({
          bagsToUpdate: bags,
          newStatus: "bagged",
          location: location
        })
        break; 
      case "pickup":
        console.log(selectedOrder)
        scanResults.forEach((charcode) => {
          let bag = {
            charcode: charcode
          };
          bags.push(bag);
        })
        requestBody = JSON.stringify({
          bagsToUpdate: bags,
          newStatus: "pickedUp",
          selectedDelivery: selectedDelivery,
          location: location
        })
        break; 
      case "delivery":
        scanResults.forEach((charcode) => {
          let bag = {
            charcode: charcode
          };
          bags.push(bag);
        })
        if(sigCanvas.current.isEmpty()) {
          alert("Please provide a signature first.");
          return;
        }
        
        const signatureDataURL = sigCanvas.current.toDataURL();

        requestBody = JSON.stringify({
          bagsToUpdate: bags,
          newStatus: "delivered",
          selectedOrder: selectedOrder,
          location: location,
          signatureUrl: signatureDataURL
        })
        break; 
    }
    console.log(requestBody)
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: requestBody
    };
    let response = await fetch(user.backEndURL + "/charcodes", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      setScreen("success");
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      console.log("Fetch Subscription Failed...")
    }

    console.log("HEREEEEE")
  }

  const resetSignature = () => {

  }
  
  const renderContent = () => {
    switch(screen){
      case "loading": return (
        <> 
          Loading...
        </>
      );
      case "error": return (
        <> 
          Oops something went wrong..
          <p>To enable location access, try the following steps:</p>
          <ul>
            <li>Refresh page and accept location settings</li>
            <li>Click the padlock icon in the address bar.</li>
            <li>Go to Site settings.</li>
            <li>Find the Location section and set it to Allow.</li>
            <li>Reload the page and click Retry.</li>
          </ul>
          <Button name={"Retry"} onPress={() => setRetry(!retry)}/>
        </>
      );
      case "bagging": return (
        <> 
            <div className={styles.header}>
              Add New Biochar Bag(s)
            </div>
            <div className={styles.description}>
              Select bagging site, bag weight, and bagging date. You may process multiple bags in one go by clicking the “+ Add Bags” button
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
              </div>
              <div className={styles.collapseIcon} >
              {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
                {scanResults.map((scan) => {
                  return(
                    <div className={styles.headerCharcode}>
                      {scan}
                    </div>
                  )            
                })}
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)}/>
              : null
            }
             <div className={styles.sectionHeader}>
              <div>
                <span className={styles.headerIcon}>industry-alt</span> Site
              </div>
            </div>
            <div className={styles.inputRow}>
              <Selector name={"Site Name"} labelStyle={"none"} value={site} onChange={setSite} data={[{name: "Ahlstrom", value: "ARA"}, {name: "Jenkinson", value: "JNR"}]}/>
            </div>
            <div className={styles.inputRow}>
               <div className={helpers.clx(styles.sectionHeader, styles.weightContainer)}>
                 <TextInput disabled={false} name={"Weight (kg)"} labelStyle={"top"} iconName={"balance-scale"} value={weight} onChange={setWeight}/>
               </div>
               <div className={helpers.clx(styles.sectionHeader)}>
                 <DateSelector name={"Bagging Date"} labelStyle={"top"} iconName={"calendar-alt"} value={baggingDate} onChange={setBaggingDate}/>
               </div>
            </div>
            <div className={styles.ghostSpace}/>
            <Button disabled={site === "" ? true: false} name="Confirm Bagging" onPress={updateBagsStatus} color={"Grass"}/>
            <div className={styles.line}/>
            
        </>
      )
      case "pickup":return (
        <> 
            <div className={styles.header}>
             Confirm Pickup
            </div>
            <div className={styles.description}>
              Confirm inventory being picked up. You may process multiple bags in one go by clicking the “+ Add Bags” button
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
              </div>
              <div className={styles.collapseIcon} onClick={() => collapseCharcodes()}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
              {scanResults.map((scan) => {
                  return(
                    <div className={styles.headerCharcode}>
                      {scan}
                    </div>
                  )            
                })}
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)}/>
              : null
            }
            <div className={styles.inputRow}>
              <Selector name={"Delivery ID"} labelStyle={"top"} value={selectedDelivery} onChange={setSelectedDelivery} data={deliveries.map((delivery) => ({name: delivery.delivery_id, value: delivery._id}))} placeholder={"Select Delivery"} iconName={"truck-moving"}/>
            </div>
            <Button color={"Grass"} disabled={selectedDelivery === "" ? true : false} name="Confirm Pickup" onPress={updateBagsStatus}/>
            <div className={styles.line}/>
            <div className={styles.productInformation} onClick={() => window.open("https://www.european-biochar.org/cert/341e-2531-z3d1-5rgq", '_blank').focus()}>
              <img src={ebc} className={styles.ebcLogo}/>
              EBC Information
              <span className={styles.productFontAwesome}>
                chevron-right
              </span>
            </div>
        </>
      )
      case "delivery": return (
        <> 
          <div className={styles.header}>
             Confirm Biochar Delivery
            </div>
            <div className={styles.description}>
              Confirm inventory being picked up. You may process multiple bags in one go by clicking the “+ Add Bags” button
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
              </div>
              <div className={styles.collapseIcon}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
              {scanResults.map((scan) => {
                  return(
                    <div className={styles.headerCharcode}>
                      {scan}
                    </div>
                  )            
                })}
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)}/>
              : null
            }
              <div className={styles.inputRow}>
                <Selector name={"Order ID"} labelStyle={"top"} iconName={"truck-moving"} value={selectedOrder} onChange={setSelectedOrder} data={orders.map((order) => ({name: order.order_id + " (" + order._user.business_name + ")", value: order._id}))} placeholder={"Select Order"}/>
              </div>
              <div className={styles.sectionHeader}>
                <div>
                  <span className={styles.headerIcon}>signature</span> Customer Signature
                </div>
              </div>
              <div className={styles.signatureContainer}>
                <SignatureCanvas penColor='black' canvasProps={{/*width: 500, height: 200,*/ className: styles.signature}} ref={sigCanvas}/>
                <div className={styles.resetSignature} onClick={() => clearCanvas()}>trash-restore-alt</div>
              </div>
            <Button disabled={selectedOrder === "" ? true : false} name="Confirm Delivery" onPress={updateBagsStatus} color={"Grass"}/>
            <div className={styles.line}/>
            <div className={styles.productInformation} onClick={() => window.open("https://www.european-biochar.org/cert/341e-2531-z3d1-5rgq", '_blank').focus()}>
              <img src={ebc} className={styles.ebcLogo}/>
              EBC Information
              <span className={styles.productFontAwesome}>
                chevron-right
              </span>
            </div>
        </>
      )
      case "application": return (
        <> 
          <img src={applicationImage} className={helpers.clx(styles.mainIllustration, styles.applicationIllustration)}/>
            <div className={styles.header}>
              <span className={styles.headerGrass}>4. Confirm</span> Application
            </div>
            <div className={styles.charcodesContainer}>
              <div className={styles.headerCharcode}>
                {mainBag.charcode}
              </div>
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)}/>
              : null
            }

            <div className={styles.content}>
              <div className={styles.inputRow}>
                <TextInput name={"Number of bags used"} labelStyle={"top"} value={bagsNumber} onChange={setBagsNumber}/>
                <DateSelector name={"Application date"} labelStyle={"top"} value={applicationDate} onChange={setApplicationDate}/>
              </div>
              <div className={styles.inputRow}>
                <Selector name={"Biochar applied to"} labelStyle={"top"} value={endUse} onChange={setEndUse} data={[{name: "Bedding", value: "bedding"}, {name: "Slurry", value: "slurry"}, {name: "FYM", value: "fym"}, {name: "Direct to land", value: "directToLand"}]}/>
                <TextInput name={"Machinery used"} labelStyle={"top"} value={machinery} onChange={setMachinery}/>
              </div>
              <div className={styles.inputRow}>
                Please press on button to confirm your biochar delivery.
              </div>
            </div>
            <Button name="Confirm Delivery" onPress={updateBagsStatus}/>
        </>
      )
      case "success":return (
        <> 
          <img src={successImage} className={styles.mainIllustration}/>
          <div className={styles.header}>
            Bag(s) Successfully Updated!
          </div>
          <div className={styles.description}>
              Confirm inventory being picked up. You may process multiple bags in one go by clicking the “+ Add Bags” button
          </div>
          <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
              </div>
              <div className={styles.collapseIcon} >
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
            {scanResults.map((scan) => {
                return(
                  <div className={styles.headerCharcode}>
                    {scan}
                  </div>
                )            
              })}
          </div>
        </>
      )
    }
  }

  return (
    <div className={styles.fullContainer}>
      <div className={styles.patternBg}/>
      <img src={dots} className={styles.pattern1}/>
      <img src={dots} className={styles.pattern2}/>
      <div className={styles.websiteLink} onClick={() => window.open("https://blackbullbiochar.com", '_blank').focus()}>
        <span className={styles.fontAwesome}>globe</span> BLACKBULLBIOCHAR.COM
      </div>
      <div className={styles.blackContainer}>
        <div className={styles.logoContainer}>
          <img src={bbbLogo} className={styles.logo}/>
        </div>
        <div className={styles.container}>

          <img src={greenCircles} className={styles.circle1}/>
          <img src={greenCircles} className={styles.circle2}/>

          {renderContent()}
        </div>
      </div>
   
    </div>
    
  );
}




export default CharcodeScreen;
