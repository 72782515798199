import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './FarmerDetails.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext.js';


import TextInput from "../../components/TextInput.js";
import Button from "../../components/Button.js";
import Module from "../../components/Module.js";
import Overlay from "../../components/Overlay.js";
import Selector from "../../components/Selector.js";
import ProductCounter from "../../components/subscription/ProductCounter.js";
import countries from "../../assets/Countries.js";







function FarmerDetails(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [displayScheduleOverlay, setDisplayScheduleOverlay] = useState(false);
  const [orderToUpdate, setOrderToUpdate] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState(props.user.delivery_details);
  const [billingDetails, setBillingDetails] = useState(props.user.billing_details);
  const [editUserDetails, setEditUserDetails] = useState(false);
  const [defaultUserDetails, setDefaultUserDetails] = useState({
    email: "john.doe@gmail.com",
    firstName: "John",
    lastName: "Doe",
    businessName: "John's Dairy Farm",
    country: "GB",
    homePhone: "+44 74 458 42238",              
    mobilePhone: "+44 34 521 12038"
  });
  const [userDetails, setUserDetails] = useState({
    email: "john.doe@gmail.com",
    firstName: "John",
    lastName: "Doe",
    businessName: "John's Dairy Farm",
    country: "GB",
    homePhone: "+44 74 458 42238",              
    mobilePhone: "+44 34 521 12038"
  });
  const [updated, setUpdated] = useState(false);


  
  console.log(props.user)



  const [formData, setFormData] = useState({
    pincharBags: 0,
    pelletcharBags: 0,
    date: new Date()
  });

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };
  
  const resetUserDetails = () => {
    setEditUserDetails(false);
    setUserDetails(defaultUserDetails);
    setUpdated(false);
  }

  const handleSubmit = async () => {
    props.updateSettings(userDetails, null, null);
    setUpdated(false);
  };
  

  // const renderScheduleOrder = () => {
  //   if(displayScheduleOverlay){
  //     return(
  //       <Overlay>
  //         <div className={styles.scheduleOrderContainer}>
  //           <h2>New Order</h2>
  //           <div className={styles.datePickerContainer}>
  //             Delivery Date:
  //             {/* <DatePicker className={styles.datePicker} calendarClassName={styles.calendar} selected={formData.date} onChange={(date) => handleChange("date", date)} /> */}
  //             <input className={styles.datePicker} type="date" value={formData.date} onChange={(e) => handleChange("date", e.target.value)}/>
  //           </div>
  //         <div className={styles.productCounterContainer}>
  //           <ProductCounter name={"Pinchar"} price={"£330/Bag"} value={formData.pincharBags} handleChange={handleChange} fieldName={"pincharBags"}/>
  //         </div>
  //         <Button name={"Confirm Order"} onPress={() => scheduleOrder()}/>
  //         <Button name={"Cancel"} color={"Error"} onPress={() => setDisplayScheduleOverlay(false)}/>
  
  //         </div>
  //       </Overlay>
  //     )
  //   }else{
  //     return null
  //   }
  // }

  // const updateOrder = async (isDelete) => {
  //   const request = {
  //     method: "put",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + user.token,
  //     },
  //     body: JSON.stringify({
  //       orderId: orderToUpdate._id,
  //       userId: props.farmer._id, 
  //       status: isDelete ? "delete" : orderToUpdate.status
  //     })
  //   };
  //   let response = await fetch(user.backEndURL + "/order", request);
  //   let jsonResponse = await response.json();
  //   if (jsonResponse.success) {
  //     alert("Successfully Updated!");
  //     setOrderToUpdate(null);
  //     props.updateFarmerList();
  //   } else {
  //     alert("Something went wrong...")
  //   }
    
  // }

  return (
    <div className={helpers.clx("contentGrid", styles.contentGridNoBg)}>
      <Module icon={"user"} name={"Account Details"} spanColumn={24} height={"100%"}>
        <div className={styles.accountDetailsContainer}>
        {
          !editUserDetails ? 
            <div onClick={() => setEditUserDetails(true)} className={styles.iconEdit}>
              pencil
            </div>
          : 
            null
        }
        
          <div className={styles.accountDetailsColumn}>
            <div className={styles.inputRow}>
                <TextInput name={"E-Mail"} value={userDetails.email} onChange={(value) => handleChange("email", value)} labelStyle={"top"} placeholder={"Enter E-Mail"}/>
            </div>
            <div className={styles.inputRow}>
                <TextInput name={"Password"} value={userDetails.email} onChange={(value) => handleChange("email", value)} labelStyle={"top"} placeholder={"Enter E-Mail"} isPassword={true}/>
            </div>
            <div className={styles.inputRow}>
                <TextInput name={"First Name"} value={userDetails.firstName} onChange={(value) => handleChange("firstName", value)} labelStyle={"top"} placeholder={"Enter First Name"}/>
            </div>
            <div className={styles.inputRow}>
                <TextInput name={"Last Name"} value={userDetails.lastName} onChange={(value) => handleChange("lastName", value)} labelStyle={"top"} placeholder={"Enter Last Name"}/>
            </div>
          </div>
          <div className={styles.accountDetailsColumn}>
            <div className={styles.inputRow}>
                <TextInput name={"Business Name"} value={userDetails.businessName} onChange={(value) => handleChange("businessName", value)} labelStyle={"top"} placeholder={"Enter Business Name"}/>
            </div>
            <div className={styles.inputRow}>
              <Selector name="Country" value={userDetails.country} data={countries} onChange={(value) => handleChange("country", value)} labelStyle="top" placeholder="Select Country" color="white"/>
            </div>
            <div className={styles.inputRow}>
                <TextInput name={"Home Phone"} value={userDetails.homePhone} onChange={(value) => handleChange("homePhone", value)} labelStyle={"top"} placeholder={"Enter Home Phone"}/>
            </div>
            <div className={styles.inputRow}>
                <TextInput name={"Mobile Phone"} value={userDetails.mobilePhone} onChange={(value) => handleChange("mobilePhone", value)} labelStyle={"top"} placeholder={"Enter Mobile Phone"}/>
            </div>
          </div>
       
        </div>
        {
          editUserDetails ? 
            <div className={styles.buttonRow}>
                <Button onPress={() => resetUserDetails()} name={"Cancel"} color={"Error"}/>
                <Button onPress={() => handleSubmit()} name={"Update"} color={"Grass"}/>
            </div>
          : 
            null
        }
          
      </Module>
      <Module icon={"location-arrow"} name={"Delivery Details"} spanColumn={12} height={"100%"}>
        <div className={styles.detailsWrapper}>
          <div className={styles.detailsRow}>
            <div className={styles.detailsRowSection}>
              <span className={styles.detailsRowSectionHeader}>Address</span>
              {deliveryDetails.addressLine1}<br/>
              {deliveryDetails.addressLine2}<br/>
              {deliveryDetails.city}<br/>
              {deliveryDetails.county}<br/>
              {deliveryDetails.zip}
            </div>
            <div className={styles.detailsRowSection}>
              <span className={styles.detailsRowSectionHeader}>Contact</span>
              {deliveryDetails.firstName} {deliveryDetails.lastName}<br/>
              {deliveryDetails.email}<br/>
              {deliveryDetails.phone}<br/>
            </div>
          </div>
          <div className={styles.detailsRow}>
          <div className={helpers.clx(styles.detailsRowSection, styles.detailsRowSectionFullWidth)}>
              <span className={styles.detailsRowSectionHeader}>Delivery Instructions</span>
              {deliveryDetails.instructions}
              {/* When you reach the main road, turn left and continue for 3 miles until you see a large green barn on your right.
              Turn right onto the dirt road directly after the barn.
              Follow the dirt road for 1.5 miles until you reach a split in the road.
              Take the right fork and continue for 1 mile until you see a red farmhouse on your left.
              Turn left onto the gravel driveway just before the farmhouse and continue to the back of the property.
              The delivery location is the large metal storage shed behind the farmhouse.
              Please be aware that the driveway and roads leading to the farm are unpaved and may be difficult to navigate for larger vehicles.
              If you have any trouble finding the location, please call the contact provided for further assistance. */}
            </div>
          </div>
          <Button onPress={() => props.goToScreen("update-delivery-details", {deliveryDetails: deliveryDetails})} name={"Update Delivery Details"} color="Coal"/>
        </div>
      </Module>
      <Module icon={"money-bill"} name={"Billing Details"} spanColumn={12} height={"100%"}>
      <div className={styles.detailsWrapper}>
          <div className={styles.detailsRow}>
            <div className={styles.detailsRowSection}>
              <span className={styles.detailsRowSectionHeader}>Address</span>
              {billingDetails.addressLine1}<br/>
              {billingDetails.addressLine2}<br/>
              {billingDetails.city}<br/>
              {billingDetails.county}<br/>
              {billingDetails.zip}
            </div>
            <div className={styles.detailsRowSection}>
              <span className={styles.detailsRowSectionHeader}>Contact</span>
              {billingDetails.firstName} {billingDetails.lastName}<br/>
              {billingDetails.email}<br/>
              {billingDetails.phone}<br/>
            </div>
          </div>
          <Button onPress={() => props.goToScreen("update-billing-details", {billingDetails: billingDetails})} name={"Update Billing Details"} color="Coal"/>
        </div>
      </Module>
    </div>
  );
}

export default FarmerDetails;
