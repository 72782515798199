import {Html5QrcodeScanner} from "html5-qrcode";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";

import styles from './QRCodeScanner.module.css'; //styles

import qrIcon from "../assets/images/qr.png";

const QRScanner = (props) => {
  const [scannerInitialized, setScannerInitialized] = useState(false)
  const [scanResults, setScanResults] = useState(props.scanResults);

  // const reader = useRef(null); // Create a ref using useRef


 
  const success = useCallback((result, clearScanner) => {
    // clearScanner.clear();
    setScannerInitialized(false);
    setScanResults(prevScanResults => [...prevScanResults, result])
    console.log(scanResults);
    props.addCharcode(result.slice(-10))
    // props.updateScannedCharcodes(result, true)
  }, [props.scanResults])


  useEffect(() => {
    setScanResults(props.scanResults);
    console.log("scanResults");
    console.log(props.scanResults);

    console.log(scanResults);
  }, [props.scanResults.slice()])


  const error = (err) => {
    // console.log(err)
  }

  useEffect(() => {
    console.log("USEFFC")
    if(!scannerInitialized){
      setScannerInitialized(true);
      const scanner = new Html5QrcodeScanner("reader", {
        qrbox: {
          width: 300,
          height: 300,
        },
        aspectRatio: "1.0",
        fps: 10,
      },[])
  
      
      // alert("ONCE")
      console.log("AQUI")
      scanner.render((result) => success(result, scanner.clear()), error);
    }
    console.log("HERE")
  },[props.scanResults.slice()])
  // console.log(props.scanResults)
  return(
    <div className={styles.container}>
      <div className={styles.closeIcon} onClick={() => props.closeScanner()}>chevron-left</div>
      {/* <div className={styles.scanResult}>{props.scanResults}</div> */}
      <div className={styles.header}>
        Scan Additional Charcodes
      </div>
      <div>
        {
          scannerInitialized ? 
            <img src={qrIcon} className={styles.qrIcon}/>
          : null
        }

        <div className={styles.reada} id={"reader"}>
        </div>
      </div>
   
      <div className={styles.bagsNumber}>
        <span className={styles.fontAwesome}>
          shopping-bag
        </span> 
        Bags{"(" + props.scanResults.length + ")"}
      </div>
      <div className={styles.scannedCodes}>
        {props.scanResults.map((charcodeUrl)=> {
          let charcode = charcodeUrl.substring(charcodeUrl.length - 10);
          return(
            <div className={styles.charcodeTag} onClick={() => props.removeCharcode(charcodeUrl)}>{charcode}<div className={styles.backspaceIcon}>backspace</div></div>
          )
        })} 
      </div>
      
    </div>
  )
}

export default QRScanner;





// import React, { useRef, useEffect, useState } from 'react';
// import jsQR from 'jsqr';

// const QRScanner = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [scannedResult, setScannedResult] = useState(null);

//   useEffect(() => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');

//     const constraints = {
//       audio: false,
//       video: { facingMode: 'environment' } // Use rear camera if available
//     };

//     const handleSuccess = (stream) => {
//       video.srcObject = stream;
//       video.onloadedmetadata = () => {
//         video.play().catch((err) => console.error('Error playing video:', err));
//       };
//       requestAnimationFrame(scanFrame);
//     };

//     const scanFrame = () => {
//       if (video.readyState === video.HAVE_ENOUGH_DATA) {
//         canvas.height = video.videoHeight;
//         canvas.width = video.videoWidth;
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
//         const code = jsQR(imageData.data, imageData.width, imageData.height);
//         if (code) {
//           alert(code.data)
//           setScannedResult(code.data);
//         }
//       }
//       requestAnimationFrame(scanFrame);
//     };

//     const handleError = (err) => {
//       console.error('Error accessing camera:', err);
//     };

//     navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError);

//     return () => {
//       if (video.srcObject) {
//         alert("Hello")
//         video.srcObject.getTracks().forEach((track) => track.stop());
//       }
//     };
//   }, []);

//   return (
//     <div style={{"width": "200px", "height": "200px", "zIndex": "99999999", "background": "pink"}}>
//       h{scannedResult}a
//       <video ref={videoRef} style={{ display: 'none' }} />
//       <canvas ref={canvasRef} style={{ display: 'none' }} />
//       {scannedResult && <p>Scanned QR code: {scannedResult}</p>}
//     </div>
//   );
// };

// export default QRScanner;


/*
import React, { useState } from 'react';
import { QrReader } from '@cmdnio/react-qr-reader';

const QRScanner = () => {
  const [data, setData] = useState('No result');

  return (
    <>
      <QrReader
        constraints={{
          aspectRatio: "1",
          facingMode: "environment"
        }}
        scanDelay={250}
        onResult={(result, error) => {
          if (!!result) {
            console.log(result.getText())
          }
        }}
        ViewFinder={() => (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              zIndex: 100000000
            }}
          >overlay</div>
        )}
        videoContainerStyle={{}}
        videoStyle={{}}
      />
      <p>A{data}A</p>
    </>
  );
};

export default QRScanner;
*/