
import {useState} from "react";
import styles from './NotificationSettings.module.css';
import helpers from '../../helpers.js';

import Button from "../Button.js";
import Checkbox from "../Checkbox.js";











function NotificationSettings(props) {
  const [errorPrompt, setErrorPrompt] = useState("");
  const [highlighedtInput, setHighlighedtInput] = useState("");
  const [updated, setUpdated] = useState(false);
  const [defaultNotificationSettings, setDefaultNotificationSettings] = useState({
    newsletter: false,
    alertsEmail: false,
    alertsSMS: true,
    paperInvoices: false
  });
  const [notificationSettings, setNotificationSettings] = useState({
    newsletter: false,
    alertsEmail: false,
    alertsSMS: true,
    paperInvoices: false
  });

  const handleChange = (formField, value) => {
    setNotificationSettings({ ...notificationSettings, [formField]: value });
    setUpdated(true);
  };

  const resetNotificationSettings = () => {
    setNotificationSettings(defaultNotificationSettings);
    setUpdated(false);
  }

  return (
    <div className={styles.notificationSettingsContainer}>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Subscribe To BBB Newsletter
          </div>
          <div className={styles.inputContainer}>
            <Checkbox text="" checked={notificationSettings.newsletter} onPress={() => handleChange("newsletter", !notificationSettings.newsletter)}/>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Receive Alerts By Email
          </div>
          <div className={styles.inputContainer}>
            <Checkbox text="" checked={notificationSettings.alertsEmail} onPress={() => handleChange("alertsEmail", !notificationSettings.alertsEmail)}/>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Receive Alerts By SMS
          </div>
          <div className={styles.inputContainer}>
            <Checkbox text="" checked={notificationSettings.alertsSMS} onPress={() => handleChange("alertsSMS", !notificationSettings.alertsSMS)}/>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Receive Paper Invoices
          </div>
          <div className={styles.inputContainer}>
            <Checkbox text="" checked={notificationSettings.paperInvoices} onPress={() => handleChange("paperInvoices", !notificationSettings.paperInvoices)}/>
          </div>
        </div>
        <div className={styles.buttonRow}>
            <Button disabled={updated ? false : true} onPress={() => resetNotificationSettings()} name={"Update"} color={"Grass"}/>
            <Button disabled={updated ? false : true} onPress={() => resetNotificationSettings()} name={"Cancel"} color={"Error"}/>
        </div>
    </div>         
  );
}

export default NotificationSettings;
