import { useNavigate } from "react-router-dom";
import { useState, useCallback, useContext, useEffect } from "react";

import { UserContext } from "./UserContext.js";
import helpers from "./helpers.js";
import styles from './LoginScreen.module.css'; //styles

import TextInput from "./components/TextInput.js"; 
import Button from "./components/Button.js";
import Checkbox from "./components/Checkbox.js";
import DottedLine from "./components/DottedLine.js";



import greenCircles from "./assets/images/greenCirclesCorner.png";
import bbbLogo from "./assets/images/bbbLogo.png";
import bbbLogoWhite from "./assets/images/bbbLogoWhiteTransparentNoText.png";


function LoginScreen(props) {
  const {user, setUser} = useContext(UserContext);

  const [email, setEmail] = useState("");//ADMIN.adrien@blackbullbiochar.com
  const [password, setPassword] = useState("");
  const [keepMeLoggedIn, setkeepMeLoggedIn] = useState(true);

  const [screenState, setScreenState] = useState("login");
  const [errorPrompt, setErrorPrompt] = useState("");
  const [highlighedtInput, setHighlighedtInput] = useState("");


  const navigate = useNavigate(); //use callback
  console.log(email);
  const login = useCallback( async() => {
    // DELETE LINE

    // return navigate("/admin/farmers")

    console.log(email);
    console.log("HEEERE");
    console.log(password);
    if(!helpers.isValidEmail(email)){
      helpers.errorPrompt("Please Enter Valid Email", "email", setErrorPrompt, setHighlighedtInput)
      return;
    } else if (password.length < 6){
      helpers.errorPrompt("Please Enter Valid Password", "password", setErrorPrompt, setHighlighedtInput)
      return;
    }

    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        password: password
      }),
    };

    let response = await fetch(user.backEndURL + "/login", request);
    let jsonResponse = await response.json();
    console.log(jsonResponse);
    console.log("AAAAAAAAAAAAAAA");

    if (jsonResponse.success && jsonResponse.data.isAdmin) {

  
      let userObj = user;
      props.setAdminDetails(userObj, jsonResponse.data, keepMeLoggedIn);
      setUser(userObj);
      console.log("IS ADMINNNNN");
      return navigate("/admin/timeline")

    } if (jsonResponse.success) {
      let userObj = user;
      props.setUserDetails(userObj, jsonResponse.data, keepMeLoggedIn);
      setUser(userObj);
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
      return navigate("/subscription")

    } else {
      helpers.errorPrompt("Invalid Username / Password", "", setErrorPrompt, null)
      console.log("SILENTLY FAILS, LIKE A NINJA")
      return;
    }
    
  }, [user, password, email, keepMeLoggedIn]);

  useEffect(() => {
    //Login when ENTER pressed
    const handleKeyDown = (e) => {
      if(e.keyCode === 13){
        login();
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    // Remove Listener
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [user, email, password, keepMeLoggedIn]);

  const goToSignUp = useCallback(() => {
    setScreenState("goToSignUp");
    setTimeout(() => {
      return navigate("/sign-up")
    }, 700)
  }, [])

  const goToForgotPassword = useCallback(() => {
    setScreenState("goToForgotPassword");
    setTimeout(() => {
      return navigate("/forgot-password")
    }, 700)
  }, [])
  
  return (
    <div className={'mainBlackContainer'}>
      <div className={helpers.clx(styles.blackContainerContent, screenState === "goToSignUp" ? styles.blackContainerContentGoToSignUp : "", screenState === "goToForgotPassword" ? styles.blackContainerContentGoToForgotPassword : "" )}>
        <div className={styles.bbbLogoWhiteContainer}>
          <img src={bbbLogoWhite} className={styles.bbbLogoWhite}/>
          <div className={styles.bbbLogoText}>
            BLACK BULL BIOCHAR
          </div>
        </div>
        <div className={helpers.clx(styles.welcomeMessageContainer, screenState === "goToSignUp" ? styles.welcomeMessageContainerGoToSignUp : "", screenState === "goToForgotPassword" ? styles.welcomeMessageContainerGoToForgotPassword : "" )}>
          <DottedLine color="grass" segmentNumber={5} segmentWidth="0.5rem" segmentHeight="1.7rem"/>
          <div className={styles.welcomeMessageTextContainer}>
            <div className={styles.welcomeHeader}>
              Hello!<span className={styles.headerGreen}>&nbsp;Welcome Back.</span>
            </div>
            <div className={styles.welcomeText}>
              Don't have an account yet? If you already have a referral code, go ahead and&nbsp;<span className={styles.textLink} onClick={goToSignUp} >create a new account.</span> <br/><br/> Interested in buying or selling biochar?&nbsp;<a href={"https://www.blackbullbiochar.com/contact-us"} target="_blank" rel="noopener noreferrer" className={styles.textLink}>Get in touch</a> with our team.
            </div>
          </div>
        </div>
        
      </div>
      <div className={helpers.clx("mainWhiteContainer", styles.mainWhiteContainer, screenState === "goToSignUp" ? styles.mainWhiteContainerGoToSignUp : "", screenState === "goToForgotPassword" ? styles.mainWhiteContainerGoToForgotPassword : "")}>
        <div className={styles.logoContainer}>
          <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
        </div>
        <div className={styles.greenCirclesWrapper}>
            <img src={greenCircles} className={helpers.clx(styles.greenCircle1, screenState !== "login" ? styles.greenCircle1Hidden : "")} alt="Green Circle"/>
            <img src={greenCircles} className={helpers.clx(styles.greenCircle2, screenState !== "login" ? styles.greenCircle2Hidden : "")} alt="Green Circle"/>
        </div>
        <div className={helpers.clx(styles.loginContent, screenState !== "login" ? styles.loginContentHidden : "")}>
          <h2 className={styles.loginHeader}>
            Account Login
          </h2>
          <TextInput name="Email" value={email} onChange={setEmail} highlighted={highlighedtInput === "email" ? true : false} iconName="envelope" labelStyle="top" placeholder="Enter Email"/>
          <TextInput name="Password" value={password} onChange={setPassword}  highlighted={highlighedtInput === "password" ? true : false} iconName="key" labelStyle="top" placeholder="Password" isPassword={true}/>
          <div className={styles.loginOptionsContainer}>
            <Checkbox text="Keep Me Logged In" checked={keepMeLoggedIn} onPress={() => setkeepMeLoggedIn(!keepMeLoggedIn)}/>
            <div className={styles.forgotPassword} onClick={goToForgotPassword}>
              Forgot Password?
            </div>
          </div>
          <Button disabled={false} onPress={login} name="Login" color="Coal"/>
          <div className={helpers.clx("errorPrompt", styles.errorPromptContainer)}>{errorPrompt}</div>

        </div>
      </div>
    </div>
  );
}


export default LoginScreen;
