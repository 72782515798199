
import styles from './CharcodesScreen.module.css';
import helpers from '../helpers.js';
import {useState, useContext, useEffect, useRef} from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';

import { UserContext } from '../UserContext.js';



import Nav from "../components/Nav.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Module from "../components/Module.js";
import TextInput from "../components/TextInput.js";
import Selector from "../components/Selector";
import Button from "../components/Button.js";
import Charcode from "../components/Charcode.js";



const params = {
  html2CanvasOptions: {
      backgroundColor: null,
      scale: 10
    }
};

function CharcodesScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [charcodeAmount, setCharcodeAmount] = useState(0);
  const [charcodes, setCharcodes] = useState(["CHA-000001"])
  const ref = useRef(null);

  const generateCharcodes = async () => {
    setCharcodes([])
    const request = {
      body: JSON.stringify({
        amount: charcodeAmount
      }),
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    console.log(user.backEndURL);
    let response = await fetch(user.backEndURL + "/charcodes", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse.data)
      setCharcodes(jsonResponse.data.bags)
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  useEffect(() => {
    console.log("Much Ado About Nothing...")
  },[]) 


  const renderCharcodes = () => {
    if(!charcodes || charcodes.length === 0){
      console.log("NO CHARCODES");
      return <div className={styles.placeholder}>No data to display, generate Charcodes first...</div>
    } else {
      return (
        <>
        { charcodes.map((charcode) => {
          return(
            <div className={styles.charcodeContainer}>
              <Charcode charcodeId={charcode}/>
            </div>
          )
           
        })}
        </>
      )
     
    }
    
  }

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} isAdmin={true} currentScreen={"admin/charcodes"}/>
        {/* {renderFarmerInvite()} */}

      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Generate Charcodes"} content={"Generate charcodes, export them and print them"}/>
          <div className={styles.contentContainer}>
            <div className={styles.dottedLine}>
              <div ref={ref} className={styles.charcodesContainer}>
                {renderCharcodes()}
              </div>
            </div>
            
           
          
            <div className={styles.settingsContainer}>

              <div className={styles.settingsHeader}>
                Generator Settings
              </div>
              <TextInput value={charcodeAmount} onChange={setCharcodeAmount} name={"Number of Charcodes"} placeholder={"Enter Value"} labelStyle={"top"}/>
              <div className={styles.charcodesPerPage}>
                <TextInput name={"Charcodes per page"} placeholder={"Enter Value"} labelStyle={"top"} disabled={true}/>

              </div>
              <div className={styles.buttonsContainer}>
                <Button name={"Generate"} value={charcodeAmount} onPress={generateCharcodes} color={"Coal"}/>
                <Button name={"Download"} onPress={() => exportComponentAsPNG(ref, params)} disabled={charcodes.length === 0 ? true : false}/>
              </div>

              
            </div>

          </div>
        </div>
        
      </div>
    </div>
  );
}


export default CharcodesScreen;
