
import {useState} from "react";
import styles from './UpdateLogo.module.css';
import helpers from '../../helpers.js';

import Button from "../Button.js";

import logoPlaceholder from "../../assets/images/logoPlaceholder.png";


function UpdateLogo(props) {
  const [errorPrompt, setErrorPrompt] = useState("");
  const [highlighedtInput, setHighlighedtInput] = useState("");



  return (
    <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={logoPlaceholder} className={styles.logo}/>
        </div>
        <div className={styles.buttonRow}>
            <Button onPress={() => console.log("HELLO")} name={"Update"} color={"Coal"}/>
            <Button onPress={() => console.log("HELLO")} name={"Remove"} color={"Error"}/>
        </div>
    </div>         
  );
}

export default UpdateLogo;
