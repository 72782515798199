
import {useState, useEffect, useContext} from "react";
import { UserContext } from '../../UserContext.js';

import styles from './PlatformSettings.module.css';
import helpers from '../../helpers.js';

import TextInput from "../TextInput.js";
import Button from "../Button.js";
import Selector from "../Selector.js";
import Checkbox from "../Checkbox.js";
import ToggleSwitch from "../ToggleSwitch.js";




import languages from "../../assets/Languages.js";







function PlatformSettings(props) {
  const {user, setUser} = useContext(UserContext);

  const [errorPrompt, setErrorPrompt] = useState("");
  const [highlighedtInput, setHighlighedtInput] = useState("");
  const [updated, setUpdated] = useState(false);
  const [defaultPlatformSettings, setDefaultPlatformSettings] = useState({
    language: "en-UK",
    enableAdvancedAnalytics: false,
    enableDarkMode: false,
    metricVolumeUnits: true,
    metricSurfaceUnits: true
  });
  const [platformSettings, setPlatformSettings] = useState({
    language: "en-UK",
    enableAdvancedAnalytics: false,
    enableDarkMode: false,
    metricVolumeUnits: true,
    metricSurfaceUnits: true
  });

  const handleChange = (formField, value) => {
    setPlatformSettings({ ...platformSettings, [formField]: value });
    setUpdated(true);
  };

  const resetPlatformSettings = () => {
    setPlatformSettings(defaultPlatformSettings);
    setUpdated(false);
  }

  const handleSubmit = async () => {
    props.updateSettings(null, platformSettings, null);
    setUpdated(false);
  };


  useEffect(() => {
    console.log("Fetching Platform Settings")
    const fetchPlatformSettings = async () => {

      if (user.token === null){
        console.log("NO TOKEN")
        user.authed = false;
      } else {
        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
        let response = await fetch(user.backEndURL + "/fetch-platform-settings", request);
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse.data);

          setPlatformSettings(jsonResponse.data);
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
        } else {
          console.log("Fetch Platform Settings Failed...")
        }
      }
    }
    fetchPlatformSettings();
  },[])

  return (
    <div className={styles.platformSettingsContainer}>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Language
          </div>
          <div className={styles.inputContainer}>
            <Selector name="Language" value={platformSettings.language} data={languages} onChange={(value) => handleChange("language", value)} highlighted={highlighedtInput === "language" ? true : false} labelStyle="none" placeholder="Select Language" color="white"/>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Enable Advanced Analytics
          </div>
          <div className={styles.inputContainer}>
            <Checkbox text="" checked={platformSettings.enableAdvancedAnalytics} onPress={() => handleChange("enableAdvancedAnalytics", !platformSettings.enableAdvancedAnalytics)}/>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Enable Dark Mode
          </div>
          <div className={styles.inputContainer}>
            <Checkbox text="" checked={platformSettings.enableDarkMode} onPress={() => handleChange("enableDarkMode", !platformSettings.enableDarkMode)}/>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Volume Units
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.toggleSwitchLabel}>
              yd<span className={styles.exp}>3</span> 
            </div>
            <ToggleSwitch text="" toggled={platformSettings.metricVolumeUnits} onPress={() => handleChange("metricVolumeUnits", !platformSettings.metricVolumeUnits)}/>
            <div className={styles.toggleSwitchLabel}>
              m<span className={styles.exp}>3</span>
            </div>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.labelContainer}>
            Surface Area Units
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.toggleSwitchLabel}>
              ha 
            </div>
            <ToggleSwitch text="" toggled={platformSettings.metricSurfaceUnits} onPress={() => handleChange("metricSurfaceUnits", !platformSettings.metricSurfaceUnits)}/>
            <div className={styles.toggleSwitchLabel}>
              km<span className={styles.exp}>2</span>
            </div>
          </div>
        </div>
        <div className={styles.buttonRow}>
            <Button disabled={updated ? false : true} onPress={() => handleSubmit()} name={"Update"} color={"Grass"}/>
            <Button disabled={updated ? false : true} onPress={() => resetPlatformSettings()} name={"Cancel"} color={"Error"}/>
        </div>
    </div>         
  );
}

export default PlatformSettings;
